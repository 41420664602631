import React, { useEffect, useState } from 'react'
import { saveMillionsQuotes } from '../../../utils/constants'
import { Col, Row } from 'react-bootstrap'

const SaveMillions = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '6.25rem',
      }}
    >
      <p style={{ textAlign: 'center', fontSize: '32px', maxWidth: '768px' }}>
        Save your company millions with VerifiedX
      </p>

      <div
        style={{
          width: isMobile ? '90%' : '96%',
          marginTop: 24,
        }}
      >
        <Row
          className='custom-grid used-by-cards'
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          {saveMillionsQuotes.map((item, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                maxWidth: isMobile ? '100%' : '32.9%',
              }}
            >
              <div
                className='drop-shadow'
                style={{
                  maxWidth: 570,
                  minWidth: 288,
                  height: 190,
                  backgroundColor: '#fff',
                  padding: '40px',
                  borderRadius: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ fontSize: '20px', textAlign: 'left' }}>
                  <strong>{item.quote}</strong>
                </p>

                <span style={{ fontSize: '16px', textAlign: 'left' }}>
                  {item.description}
                </span>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <p
        style={{
          fontSize: '16px',
          textAlign: 'left',
          width: isMobile ? '90%' : '96%',
          marginTop: 20,
        }}
      >
        Source: Security Boulevard and Barracuda Networks statistics on
        companies using proactive phishing protection services like VerifiedX.
      </p>
    </div>
  )
}

export default SaveMillions
