export const usedByCompanies = [
  {
    company: 'IT and Security',
    uses: [
      'Prevent phishing attacks',
      'Secure sensitive data',
      'Implement security policies',
    ],
  },
  {
    company: 'Finance',
    uses: [
      'Protect financial transactions',
      'Mitigate fraud risks',
      'Safeguard confidential information',
      'Ensure regulation compliance',
    ],
  },
  {
    company: 'Marketing',
    uses: [
      'Protect marketing campaigns',
      'Secure customer interactions',
      'Safeguard social media accounts',
      'Prevent brand impersonation',
    ],
  },
  {
    company: 'Sales',
    uses: [
      'Safeguard customer data',
      'Prevent email phishing',
      'Secure client communications',
      'Protect sales data',
    ],
  },
  {
    company: 'HR',
    uses: [
      'Secure employee data',
      'Prevent identity theft',
      'Secure onboarding processes',
      'Protect internal communications',
    ],
  },
  {
    company: 'Customer Support',
    uses: [
      'Secure customer data',
      'Prevent phishing in support channels',
      'Ensure data privacy compliance',
    ],
  },
]

export const saveMillionsQuotes = [
  {
    quote: '66%',
    description: 'reduction in phishing incidents',
  },
  {
    quote: '$14 million',
    description: 'average savings per incident avoided',
  },
  {
    quote: '60%',
    description: 'improvement in employee awareness',
  },
]

export const testimonials = [
  {
    message:
      '"VerifiedX saved me from sending money to a scammer within seconds! I love how easy it is to use."',
    name: 'Luke Jevan',
    profession: 'Student',
  },
  {
    message:
      '"I use VerifiedX Team to protect my family. Now, my kids are all safe from online scammers."',
    name: 'Tan Choudhry',
    profession: 'Parent',
  },
  {
    message:
      '"VerifiedX has saved our company millions, by helping us proactively detect scam and data breach attempts."',
    name: 'Jed Ashforth',
    profession: 'Founder & CEO',
  },
]

export const faqsPricing = [
  {
    question: "How does VerifiedX's pricing work?",
    answer:
      'Our paid plans are Plus and Team. We offer monthly plans for Plus and monthly and quarterly plans per user for Team.',
  },
  {
    question:
      'Can I use VerifiedX in popular apps and collaboration platforms?',
    answer:
      'Yes. Our QuickCheck button works seamlessly across messaging, email, and social media apps, enabling easy verification without leaving your platform.',
  },
  {
    question: 'How does VerifiedX protect my data?',
    answer:
      'We employ robust encryption and stringent security measures to safeguard your data, ensuring utmost protection and privacy.',
  },
  {
    question: 'Can non-profits and educational institutions use VerifiedX too?',
    answer:
      "Yes. The VerifiedX Team plan is built for non-profits and educational institutions too. It's very affordable, provides the utmost detection accuracy and a great team management capability for managers.",
  },
  {
    question: 'Can parents protect their children with VerifiedX?',
    answer:
      'Yes. VerifiedX Team plan is built for family protection too. Parents can subscribe and add their children to their Team account, protecting their children against online scammers. Furthermore, VerifiedX is deliberately designed in such a way that kids can easily use it without any support.',
  },
  {
    question: 'Which language is supported?',
    answer:
      "English, currently. We're currently working to add more languages very soon.",
  },
]
