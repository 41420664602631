import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404 - Page Not Found</h1>
      <p style={styles.paragraph}>Sorry, the page you are looking for does not exist.</p>
      <button style={styles.button} onClick={handleGoBack}>Go Back</button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: '#f8f9fa',
    textAlign: 'center',
  },
  header: {
    fontSize: '3rem',
    color: '#343a40',
    marginBottom: '1rem',
  },
  paragraph: {
    fontSize: '1.5rem',
    color: '#6c757d',
  },
  button: {
    marginTop: '1.5rem',
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#10a37f',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
  },
};

export default NotFound;
