import React from 'react'

const TestimonialCard = ({ testimonial }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: 395,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <h1 style={{ fontSize: '20px' }}>{testimonial.message}</h1>
        <div style={{ marginTop: '2rem' }}>
          <p
            style={{
              fontSize: '1.125rem',
              color: '#91919c',
              marginBottom: 0,
            }}
          >
            {testimonial.name}
          </p>
          <p
            style={{
              fontSize: '1.125rem',
              color: '#91919c',
            }}
          >
            {testimonial.profession}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
