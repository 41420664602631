import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button'

const MobileAppsButtons = ({ direction = true, textColor = 'white' }) => {
  const APKUrl = 'https://play.google.com/store/apps/details?id=host'
  const IOSUrl = 'https://apps.apple.com/us/app/expo-go/id982107779'
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div
        onClick={handleShow}
        style={{
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <p
          style={{
            textAlign: 'center',
            color: textColor,
            fontSize: '1.125rem',
          }}
        >
          Download the app &gt;
        </p>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Test any message and/or link from your phone and see if it’s scam
            and why
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              justifyContent: direction ? 'start' : 'center',
              alignItems: 'center',
              flexDirection: direction ? 'row' : 'column',
              gap: 10,
              marginBottom: direction ? 0 : 10,
            }}
          >
            <GooglePlayButton
              url={APKUrl}
              theme={'dark'}
              width={170}
              className={'custom-style'}
            />

            <AppStoreButton
              url={IOSUrl}
              theme={'dark'}
              width={170}
              className={'custom-style'}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MobileAppsButtons
