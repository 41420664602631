import React, { useEffect, useState } from 'react'
import Accordion from './Accordion'
import { faqsPricing } from '../../../utils/constants'

const FAQs = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        marginTop: '7.5rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '768px',
        }}
      >
        <h1 style={{ fontSize: '35.2px' }}>FAQs</h1>
      </div>

      {/* FAQs Accordion  */}
      <div
        className='center-content'
        style={{
          width: isMobile ? '95%' : '100%',
          marginTop: '6.25rem',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          maxWidth: 1280,
        }}
      >
        {faqsPricing.map((faq, index) => (
          <Accordion faq={faq} key={index} index={index + 1} />
        ))}
      </div>
    </div>
  )
}

export default FAQs
