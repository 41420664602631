import React from 'react'
import './accordion.css'
import { faqsPricing } from '../../../utils/constants'

const Accordion = ({ faq, index }) => {
  return (
    <div
      className='accordion-container'
      style={{
        borderTop: '1px solid #000',
      }}
    >
      <details
        style={{
          padding: '20px 0px',
          borderBottom:
            index === faqsPricing.length ? '1px solid #000' : 'none',
        }}
      >
        <summary style={{ display: 'flex', alignItems: 'center' }}>
          <span className='accordion-title'>{faq.question}</span>
          <span className='accordion-icon'>
            {' '}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M6 9l6 6l6 -6' />
            </svg>
          </span>
        </summary>
        <div className='accordion-content'>{faq.answer}</div>
      </details>
    </div>
  )
}

export default Accordion
