import React from 'react'
import AutoplayVideo from '../../AutoplayVideo'

const Featured = ({ marginTop, text, image, isVideo = false }) => {
  return (
    <div
      className='center-content featured'
      style={{
        // width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: marginTop,
      }}
    >
      <p style={{ textAlign: 'center', fontSize: '32px', maxWidth: '768px' }}>
        {text}
      </p>

      {isVideo ? (
        <AutoplayVideo videoUrl={image} />
      ) : (
        <img
          src={image}
          alt='verifiedX-gpt'
          style={{
            width: '100%',
            marginTop: '2rem',
            borderRadius: '5px',
          }}
        />
      )}
    </div>
  )
}

export default Featured
