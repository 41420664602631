import React, { useEffect, useState } from 'react'
import Featured from '../Featured'
import { useLocation } from 'react-router-dom'

const FavoriteApps = ({
  appsTitle = 'Get protected in all your favourite apps',
  text = 'QuickCheck button for one-click scam detection in any app',
}) => {
  const location = useLocation()
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  return (
    <div
      style={{
        width: '100% ',
        marginTop: '10rem',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <p style={{ textAlign: 'center', fontSize: '1.125rem' }}>{appsTitle}</p>

      <div className='favorite-apps'>
        <img
          loading='lazy'
          src='/assets/images/social/envelope.svg'
          alt='envelop'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/facebook.svg'
          alt='facebook'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/sms.svg'
          alt='sms'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/x-twitter.svg'
          alt='twitter'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/social-linkedin.svg'
          alt='linkedin'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/whatsapp.svg'
          alt='whatsapp'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/telegram.svg'
          alt='telegram'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/instagram-square.svg'
          alt='instagram-square'
          width='53px'
        />
        <img
          loading='lazy'
          src='/assets/images/social/tik-tok.svg'
          alt='tik-tok'
          width='53px'
        />
      </div>

      {width > 600 ? (
        <Featured
          marginTop='3rem'
          text={text}
          image={
            location.pathname === '/'
              ? 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1724421075/zfylbyxqmw4ogxwsrqri.mp4'
              : 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1724422501/i8oozpncopbldwzmtxs1.mp4'
          }
          isVideo
        />
      ) : (
        <Featured
          marginTop='3rem'
          text={text}
          image={
            location.pathname === '/'
              ? 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1725382413/overview/mobile/naoxrqhub0ozsjvrieko.mp4'
              : 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1725382364/team/mobile/feafbp97a3lhadg9j1sd.mp4'
          }
          isVideo
        />
      )}
    </div>
  )
}

export default FavoriteApps
