import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import MobileAppsButtons from '../../MobileAppsButtons'
import { MdArrowOutward } from 'react-icons/md'

const QuestionsCard = ({
  marginTop = '6.25rem',
  isReverse = true,
  image,
  imageBgColor = '#000',
  heading,
  btnText,
  btnLink,
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const setFlexDirection = () => {
    if (isMobile) {
      return isReverse ? 'column-reverse' : 'column'
    } else {
      return isReverse ? 'row-reverse' : 'row'
    }
  }

  const setBorderRadius = () => {
    if (isMobile) {
      return isReverse ? '10px 10px 0px 0px' : '0px 0px 10px 10px'
    } else {
      return isReverse ? '10px 0px 0px 10px' : '0px 10px 10px 0px'
    }
  }

  return (
    <div
      className='drop-shadow'
      style={{
        backgroundColor: '#fff',
        borderRadius: 10,
        position: 'relative',
        width: isMobile ? '95%' : '100%',
        height: isMobile ? '100%' : '45vw',
        padding: 0,
        marginTop: marginTop,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          display: 'flex',
          flexDirection: setFlexDirection(),
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '20px 40px 0px',
          }}
        >
          <span
            style={{
              fontSize: 19.2,
              padding: 0,
            }}
          >
            {heading}
          </span>
          <br />

          {isMobile ? (
            <>
              <a
                href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
                style={{
                  textDecoration: 'none',
                  width: 'fit-content',
                }}
              >
                <p
                  style={{
                    textAlign: 'center',
                    width: 'fit-content',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'black',
                    padding: '8px 15px',
                    borderRadius: 100,
                    backgroundColor: '#bbff4b',
                    border: '1px solid #323232',
                    fontSize: '1.125rem',
                  }}
                >
                  Start now{' '}
                  <span style={{ fontSize: 20 }}>
                    <MdArrowOutward size={20} />
                  </span>
                </p>
              </a>
              <MobileAppsButtons direction={false} />
            </>
          ) : (
            <a
              className='cta-button-hero'
              style={{
                textDecoration: 'none',
                width: '200px',
                fontSize: 16,
                height: 40,
                marginRight: 'auto',
                marginLeft: 'auto',
                textAlign: 'center',
                marginBottom: isMobile ? '2rem' : '',
              }}
              target='_blank'
              rel='noreferrer'
              href={btnLink}
            >
              {btnText}
            </a>
          )}
        </div>

        <Col md={6} sm={12} height='100%' style={{ padding: 0 }}>
          <img
            style={{
              width: '100%',
              height: isMobile ? '100%' : '45vw',
              objectFit: 'cover',
              backgroundColor: imageBgColor,
              borderRadius: setBorderRadius(),
            }}
            alt='Pattern'
            src={image}
            thumbnail
          />
        </Col>
      </div>
    </div>
  )
}

export default QuestionsCard
