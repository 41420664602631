import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import MobileAppsButtons from '../../MobileAppsButtons'
import { MdArrowOutward } from 'react-icons/md'

export default function HeroSection() {
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  useEffect(() => {
    // Ensuring autoplay works by checking ready state and playing the video
    document.addEventListener('DOMContentLoaded', (event) => {
      const video = document.getElementById('background-video')
      video.muted = true
      video.playbackRate = 0.5
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error)
      })
    })
  }, [])

  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className='mySwiper'
      >
        <SwiperSlide>
          <div
            className='hero-section-main'
            style={{
              position: 'relative',
              backgroundColor: 'black',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                width: width > 600 ? '55%' : '90%',
              }}
            >
              {/* <video autoPlay muted loop playsInline id='background-video'>
                <source
                  src='https://res.cloudinary.com/dmdcur6t5/video/upload/v1724686712/hero-section/g5ybw7cr0fcxbzfbnwcw.mp4'
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video> */}
              <h1
                style={{
                  fontSize: width > 600 ? 62 : 20,
                  color: 'white',
                }}
              >
                Test any message and link to see if it's phishing/scam or not,
                and WHY with VerifiedX
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className='hero-section-main'
            style={{
              position: 'relative',
              backgroundColor: '#323232',
            }}
          >
            <div
              className='hero-image'
              style={{
                display: 'flex',
                textAlign: 'center',
                width: '90%',
              }}
            >
              {/* <img
                className='hero-Visual'
                src='https://res.cloudinary.com/dmdcur6t5/image/upload/v1724702402/hero-section/bazm6qkotyoppxcl8pfi.jpg'
                alt='hero'
              /> */}
              <div>
                <h1
                  style={{
                    fontSize: width > 600 ? 62 : 28,
                    color: 'white',
                  }}
                >
                  Copy, paste, test any message, in just 2 clicks
                </h1>
                <p
                  style={{
                    fontSize: 18,
                    color: 'white',
                  }}
                >
                  Protect yourself against scams and phishing everywhere online.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className='hero-section-main'
            style={{
              position: 'relative',
              backgroundColor: 'black',
            }}
          >
            <div
              className='hero-image'
              style={{
                // position: 'absolute',
                display: 'flex',
                textAlign: 'center',
                width: '90%',
              }}
            >
              {/* <img
                className='hero-Visual'
                src='https://res.cloudinary.com/dmdcur6t5/image/upload/v1724702402/hero-section/cpp807to8eqmm3miplmn.jpg'
                alt='hero'
              /> */}
              <div>
                <h1
                  style={{
                    fontSize: width > 600 ? 62 : 28,
                    color: 'white',
                  }}
                >
                  VerifiedX for Business
                </h1>
                <p
                  style={{
                    fontSize: 18,
                    color: 'white',
                  }}
                >
                  Protect your Business and Team against phishing, fraud and
                  data theft with the power of AI.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className='hero-section-main'>
            <div className='hero-image'>
              <img
                className='hero-Visual'
                src='https://res.cloudinary.com/dmdcur6t5/image/upload/v1724702402/hero-section/fkzwnkakl7eeab0bjufg.jpg'
                alt='hero'
              />
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>

      <div
        style={{
          marginTop: '7rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '768px',
        }}
      >
        <p style={{ textAlign: 'center', fontSize: '1.125rem' }}>VerifiedX</p>
        <h1 style={{ fontSize: '48px' }}>
          Stay safe from scams and phishing. Across all apps and devices.
        </h1>
        <p style={{ textAlign: 'center', fontSize: '1.125rem' }}>
          Cheaper than your daily Starbucks. Just paste any message or link and
          VerifiedX will tell you with 99.999% accuracy if it's scam and why.
        </p>

        {width > 600 ? (
          <a
            href={`${process.env.REACT_APP_VERIFIEDX_HOME}`}
            style={{
              textDecoration: 'none',
              width: 'fit-content',
            }}
          >
            <div className='start-now-button'>
              Start now{' '}
              <span style={{ fontSize: 20 }}>
                <MdArrowOutward size={20} />
              </span>
            </div>
          </a>
        ) : (
          <>
            <a
              href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
              style={{
                textDecoration: 'none',
                width: 'fit-content',
              }}
            >
              <div className='start-now-button' style={{ marginBottom: 15 }}>
                Start now{' '}
                <span style={{ fontSize: 20 }}>
                  <MdArrowOutward size={20} />
                </span>
              </div>
            </a>
            <MobileAppsButtons textColor='black' direction={false} />
          </>
        )}
      </div>
    </>
  )
}
