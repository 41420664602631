import React, { useEffect, useState } from 'react'
import HeroSection from '../../components/home/HeroSection/index'
import PricingSection from '../../components/home/pricing'
import FavoriteApps from '../../components/home/FavoriteApps'
import Featured from '../../components/home/Featured'
import MobileAppsButtons from '../../components/MobileAppsButtons'
import { MdArrowOutward } from 'react-icons/md'
import AutoplayVideo from '../../components/AutoplayVideo'

export default function Home() {
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  return (
    <div
      style={{
        marginTop: 61,
        backgroundColor: '#e3e3e3',
      }}
    >
      <HeroSection />
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 1160,
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          {width > 600 ? (
            <img
              src={
                'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724686567/overview/ujtzwzkxubhcdm0drpqd.jpg'
              }
              alt='verifiedX-gpt'
              style={{
                width: '100% ',
                marginTop: '10rem',
                borderRadius: '5px',
              }}
            />
          ) : (
            <div
              style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <AutoplayVideo
                videoUrl={
                  'https://res.cloudinary.com/dmdcur6t5/video/upload/v1725382413/overview/mobile/zxqikatodcbcweeo1tac.mp4'
                }
              />
            </div>
          )}
        </div>
        <FavoriteApps />
        <Featured
          marginTop='10rem'
          text='Check messages from social media, SMS, emails and more without leaving the app'
          isVideo={width > 600 ? false : true}
          image={
            width > 600
              ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724421061/ojo6bkrssvvpqjxqioih.jpg'
              : 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1725382413/overview/mobile/iigizyzold8yoyu3qmjc.mp4'
          }
        />
        <Featured
          marginTop='10rem'
          text='Get clear reasons for why something is flagged as scam'
          image={
            width > 600
              ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724686567/overview/pkbqi8urspumr8gpfdzn.jpg'
              : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725382412/overview/mobile/ojgrpsba0cnbl2tjafes.jpg'
          }
        />
        <Featured
          marginTop='10rem'
          text='Our advanced AI detects scams with 99.999% accuracy'
          isVideo={width > 600 ? false : true}
          image={
            width > 600
              ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724421062/yo8t5zu0emhffexs5y95.jpg'
              : 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1725382413/overview/mobile/cbuzj5sw00rzilu7uqgm.mp4'
          }
        />
        <Featured
          marginTop='10rem'
          text='Protects you on mobile, PC, and more'
          image={
            width > 600
              ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724421061/xmvi7nzc4qbon568euu8.jpg'
              : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725382412/overview/mobile/fn3lmqzxxxnnxu1n1vna.jpg'
          }
          image2='/assets/overview/I.5.b.jpg'
        />
        <PricingSection />
      </div>

      <div
        className='center-content'
        style={{
          position: 'relative',
          backgroundColor: 'white',
          marginTop: '7rem',
          padding: '60px 50px 30px',
          borderLeft: '3px solid #323232',
          maxWidth: '90vw',
        }}
      >
        <p style={{ fontSize: 32, textAlign: 'left' }}>
          Millions of people lose billions of dollars every year to scams and
          phishing. Don't be one of them.
        </p>

        {width > 600 ? (
          <a
            href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
            style={{
              textDecoration: 'none',
              width: 'fit-content',
            }}
          >
            <div className='start-now-button'>
              Try VerifiedX{' '}
              <span style={{ fontSize: 20 }}>
                <MdArrowOutward size={20} />
              </span>
            </div>
          </a>
        ) : (
          <>
            <a
              href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
              style={{
                textDecoration: 'none',
                width: 'fit-content',
              }}
            >
              <div className='start-now-button' style={{ marginBottom: 15 }}>
                Start now{' '}
                <span style={{ fontSize: 20 }}>
                  <MdArrowOutward size={20} />
                </span>
              </div>
            </a>
            <MobileAppsButtons textColor='black' direction={false} />
          </>
        )}
      </div>
    </div>
  )
}
