import './App.css'
import Home from './pages/home/home'
import Pricing from './pages/pricing/pricing'
import { Route, Routes } from 'react-router-dom'
import NotFound from './pages/NotFound'
import 'bootstrap/dist/css/bootstrap.min.css'
import VerifiedNavbar from './components/Navbar'
import VerifiedFooter from './components/Footer'
import Team from './pages/team'
import ContactUs from './pages/contact-us'

function App() {
  return (
    <div className='App'>
      <VerifiedNavbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/team' element={<Team />} />
        <Route exact path='/pricing' element={<Pricing />} />
        <Route exact path='/contact' element={<ContactUs />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <VerifiedFooter />
    </div>
  )
}

export default App
