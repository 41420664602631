import React, { useEffect, useState } from 'react'
import { testimonials } from '../../../utils/constants'
import TestimonialCard from './TestimonialCard'

const Testimonials = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        marginTop: '10rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '768px',
        }}
      >
        <h1 style={{ fontSize: '35.2px' }}>What our users say</h1>
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.125rem',
            padding: '0px 60px',
          }}
        >
          From students to parents to business owners, everyone finds VerfiedX
          crucial for their online protection.
        </p>
      </div>

      {/* Testimonials Cards  */}
      <div
        className='center-content'
        style={{
          width: isMobile ? '95%' : '100%',
          marginTop: '6.25rem',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          maxWidth: 1280,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} testimonial={testimonial} />
        ))}
      </div>
    </div>
  )
}

export default Testimonials
