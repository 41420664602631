import React, { useEffect, useState } from 'react'
import { usedByCompanies } from '../../../utils/constants'

const UsedBy = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10rem',
      }}
    >
      <p style={{ textAlign: 'center', fontSize: '32px', maxWidth: '768px' }}>
        Used by every team at companies of all sizes
      </p>

      <div
        style={{
          width: '90%',
          marginTop: 24,
        }}
      >
        <div
          className='custom-grid used-by-cards'
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          {usedByCompanies.slice(0, 3).map((item, index) => (
            <div
              key={index}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                maxWidth: isMobile ? '100%' : '32.9%',
                width: '100%',
              }}
            >
              <div
                className='drop-shadow'
                style={{
                  maxWidth: isMobile ? '100%' : 570,
                  minHeight: 275,
                  height: '100%',
                  backgroundColor: isMobile ? '#c2c2c2' : 'white',
                  padding: '40px 30px',
                  borderRadius: '10px',
                }}
              >
                <p style={{ fontSize: '20px', textAlign: 'left' }}>
                  {item.company}
                </p>

                <ul id='used-by-list' style={{ paddingLeft: 16 }}>
                  {item.uses.map((use, useIndex) => (
                    <li key={useIndex}>{use}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div
          className='custom-grid used-by-cards'
          style={{ marginLeft: 0, marginRight: 0, marginTop: 8 }}
        >
          {usedByCompanies.slice(3, 6).map((item, index) => (
            <div
              key={index}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                maxWidth: isMobile ? '100%' : '32.9%',
                width: '100%',
              }}
            >
              <div
                className='drop-shadow'
                style={{
                  maxWidth: isMobile ? '100%' : 570,
                  minHeight: 275,
                  height: '100%',
                  backgroundColor: isMobile ? '#c2c2c2' : 'white',
                  padding: '40px 30px',
                  borderRadius: '10px',
                }}
              >
                <p style={{ fontSize: '20px', textAlign: 'left' }}>
                  {item.company}
                </p>

                <ul id='used-by-list' style={{ paddingLeft: 16 }}>
                  {item.uses.map((use, useIndex) => (
                    <li key={useIndex}>{use}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UsedBy
