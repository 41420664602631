import React, { useEffect, useState } from 'react'
import FeatureRow from './FeatureRow'
import { Tab, Tabs } from 'react-bootstrap'

export default function FeaturedSectionRow() {
  const [key, setKey] = useState('crossAppProtection')
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  return (
    <div
      className='featured-section-1 center-content'
      style={{ marginTop: '7.5rem' }}
    >
      <h1>Designed to maximise your team's protection</h1>

      <style type='text/css'>
        {`
          .protectionTabs > li > button.nav-link {
            color: black !important;
          }

          .protectionTabs > li > button.nav-link.active {
            background-color: #323232;
            border-radius: 50px;
            color: #fff !important;
          }
        `}
      </style>

      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className='mb-3 protectionTabs'
      >
        <Tab
          // style={{ color: '#c2c2c2' }}
          eventKey='crossAppProtection'
          title='Cross app protection'
        >
          <FeatureRow
            marginTop='4rem'
            isReverse={false}
            image={
              width > 600
                ? 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1724420691/zxerri53yrkfp9obsjgd.mp4'
                : 'https://res.cloudinary.com/dmdcur6t5/video/upload/v1725382363/team/mobile/md9qhyblpprkqnj5tc7m.mp4'
            }
            isVideo
            imageBgColor='#fbfcff'
            maxWidthFull
            heading='Scam and phishing protection in all apps using our Quick check
            button'
            description='Allows team members to quickly check suspicious messages in any app,
            enhancing productivity and minimizing scam and data breach risks.'
          />
        </Tab>
        <Tab eventKey='clearExplanations' title='Clear explanations'>
          <FeatureRow
            marginTop='6.25rem'
            isReverse={true}
            image={
              width > 600
                ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724420685/l3le0dko0jji8ow1zppb.jpg'
                : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725382363/team/mobile/wu1dbe8nxfv8l1azeciy.jpg'
            }
            // imageBgColor='#323232'
            heading='Clear explanations for every detection'
            description='Provides concise reasons for why a link or message is flagged as a scam, helping your team recognise data theft and ransomware attempts.'
          />
        </Tab>
        <Tab eventKey='accuracy' title='99.99% accuracy'>
          <FeatureRow
            marginTop='6.25rem'
            isReverse={false}
            image={
              width > 600
                ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724420685/nqtlvkyu8qmv3iifyfcu.jpg'
                : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725382363/team/mobile/xo1zty91nhny1smcdlpq.jpg'
            }
            heading='Advanced AI with 99.999% accuracy'
            description='Ensures your business is protected from sophisticated scams and proprietary data breach with near-perfect accuracy.'
          />
        </Tab>
        <Tab eventKey='protection' title='Protection on all devices'>
          <FeatureRow
            marginTop='6.25rem'
            isReverse={true}
            image={
              width > 600
                ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724420687/iemft8togjynkarlwilp.jpg'
                : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725382363/team/mobile/uvgpdkks8gg2vo0xq8lf.jpg'
            }
            // imageBgColor='#a29179'
            heading='Protection on all devices'
            description='Keeps your team secure on mobile, PC, and more, providing comprehensive coverage.'
          />
        </Tab>
        <Tab eventKey='teamManagement' title='Team management'>
          <FeatureRow
            marginTop='6.25rem'
            isReverse={false}
            imageBgColor='#fff'
            image='https://res.cloudinary.com/dmdcur6t5/image/upload/v1724420685/ac31ymmyiz5i4rzcomhv.jpg'
            heading='Add, remove, manage team members'
            description='Get a dedicated workspace for your team with admin controls, team management, and stringent security, ensuring continuous protection. We never train on your checked messages and links.'
          />
        </Tab>
        <Tab eventKey='analytics' title='ROI analytics'>
          <FeatureRow
            marginTop='6.25rem'
            isReverse={true}
            image='https://res.cloudinary.com/dmdcur6t5/image/upload/v1724420685/vke5jyxu2co3t9cvifm3.jpg'
            imageBgColor='#fff'
            heading='ROI and team protection analytics'
            description='Our useful analytics show you how much money VerifiedX has been helping you protect from cybercriminals and how your team has enjoyed VerifiedX protection.'
          />
        </Tab>
      </Tabs>
    </div>
  )
}
