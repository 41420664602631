import React, { useEffect, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import MobileAppsButtons from './MobileAppsButtons'
import { MdArrowOutward } from 'react-icons/md'

export default function VerifiedNavbar() {
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  return (
    <header
      className='fixed-top'
      style={{
        backgroundColor: '#323232',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Navbar expand='lg' className='shadow-sm'>
        <Navbar.Brand href='/'>
          <img
            src='/assets/images/Logo/Logo_hor_green.svg'
            alt='verifiedly'
            width='150'
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mx-auto '>
            <Nav.Link className='nav-text' href='/'>
              Overview
            </Nav.Link>
            <Nav.Link className='nav-text' href='team'>
              Team
            </Nav.Link>
            <Nav.Link className='nav-text' href='pricing'>
              Pricing
            </Nav.Link>
            <Nav.Link className='nav-text' href='contact'>
              Contact
            </Nav.Link>
          </Nav>

          {width > 600 ? (
            <a
              href={`${process.env.REACT_APP_VERIFIEDX_HOME}login`}
              style={{
                textDecoration: 'none',
                width: 'fit-content',
              }}
            >
              <div className='start-now-button'>Log In &#8599;</div>
            </a>
          ) : (
            <>
              <a
                href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
                style={{
                  textDecoration: 'none',
                  width: 'fit-content',
                }}
              >
                <p
                  style={{
                    textAlign: 'center',
                    width: 'fit-content',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'black',
                    padding: '8px 15px',
                    borderRadius: 100,
                    backgroundColor: 'white',
                    fontSize: '1.125rem',
                  }}
                >
                  Start now{' '}
                  <span style={{ fontSize: 20 }}>
                    <MdArrowOutward size={20} />
                  </span>
                </p>
              </a>
              <MobileAppsButtons direction={false} />
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}
