import React from 'react'

const AutoplayVideo = ({ videoUrl }) => {
  return (
    <div>
      <video
        src={videoUrl}
        playsInline
        preload
        // controls
        autoPlay
        // loop
        muted
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '100%',
        }}
      >
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

export default AutoplayVideo
