import React, { useEffect, useState } from 'react'
import FavoriteApps from '../../components/home/FavoriteApps'
import Featured from '../../components/home/Featured'
import UsedBy from '../../components/team/UsedBy'
import FeaturedSectionRow from '../../components/team/FeaturedSectionRow'
import PricingTeam from '../../components/team/PricingTeam'
import SaveMillions from '../../components/team/SaveMillions'
import { MdArrowOutward } from 'react-icons/md'

const Team = () => {
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  return (
    <div
      style={{
        marginTop: 55,
        backgroundColor: '#e3e3e3',
      }}
    >
      <div
        className='center-content'
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 1160,
        }}
      >
        <div
          style={{
            paddingTop: '7rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '768px',
          }}
        >
          <p style={{ textAlign: 'center', fontSize: '1.125rem' }}>
            VerifiedX Team
          </p>
          <h1 style={{ fontSize: '48px' }}>
            A digital superbodyguard for every member of your team
          </h1>
          <p style={{ textAlign: 'center', fontSize: '1.125rem' }}>
            Proactively protect your business against scam and phishing across
            all apps and devices with 99.999% accuracy. At a fraction of the
            cost.
          </p>

          <a
            href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
            style={{
              textDecoration: 'none',
              width: 'fit-content',
            }}
          >
            <div className='start-now-button'>
              Start now{' '}
              <span style={{ fontSize: 20 }}>
                <MdArrowOutward size={20} />
              </span>
            </div>
          </a>
        </div>

        <div
          className='center-content'
          style={{
            width: '90%',
          }}
        >
          <img
            src={
              width > 600
                ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724420684/vqiwynu0cvpxlpgqsggk.jpg'
                : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725395725/team/mobile/d5a4ago6japl1x9iwyqn.jpg'
            }
            alt='verifiedX-gpt'
            style={{
              width: '100% ',
              marginTop: '7rem',
              borderRadius: '5px',
            }}
          />
        </div>
        <FavoriteApps
          appsTitle='Protect your team in all their favorite apps'
          text='Protect your business against scams and phishing across all apps and devices'
        />
        <Featured
          marginTop='10rem'
          text='Add, remove, manage team members'
          image={
            width > 600
              ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724423431/aimjy0hdchac6o6399vm.jpg'
              : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725382363/team/mobile/v66msigvfpvb18kmltpw.jpg'
          }
        />
        <Featured
          marginTop='10rem'
          text='See the number of scam/phishing prevented in total and per team member, along with the resulting ROI and cost savings'
          image={
            width > 600
              ? 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1724420684/epspywevjjqpzs8colzc.jpg'
              : 'https://res.cloudinary.com/dmdcur6t5/image/upload/v1725382363/team/mobile/bvzoc95eoaeungdgwykh.jpg'
          }
        />
      </div>

      <UsedBy />
      <FeaturedSectionRow />
      <PricingTeam />
      <SaveMillions />

      <div
        className='center-content'
        style={{
          position: 'relative',
          marginTop: '7rem',
          maxWidth: '96vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            fontSize: 32,
            padding: '12px 20px',
            borderLeft: '3px solid #323232',
          }}
        >
          "Using VerifiedX has significantly reduced phishing incidents and
          saved us millions. Their proactive approach has improved our team's
          ability to spot scams, thanks to on-the-spot explanations for each
          detection."
        </p>
        <p
          style={{
            fontSize: '16px',
            marginTop: 20,
          }}
        >
          Michael Roberts, Cybersecurity Lead
        </p>

        <a
          href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
          style={{
            textDecoration: 'none',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'fit-content',
          }}
        >
          <div className='start-now-button'>
            Start now{' '}
            <span style={{ fontSize: 20 }}>
              <MdArrowOutward size={20} />
            </span>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Team
