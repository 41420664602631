import React from 'react'
import ContactUsForm from './Form/ContactUsForm'

const ContactForm = () => {
  return (
    <div
      className='center-content'
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        marginTop: '4rem',
        maxWidth: '768px',
      }}
    >
      <ContactUsForm />
    </div>
  )
}

export default ContactForm
