import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import AutoplayVideo from '../../AutoplayVideo'

const FeatureRow = ({
  marginTop = '6.25rem',
  isReverse = true,
  image,
  imageBgColor = '#000',
  heading,
  description,
  isVideo = false,
  maxWidthFull = false,
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const setFlexDirection = () => {
    if (isMobile) {
      return isReverse ? 'column-reverse' : 'column'
    } else {
      return isReverse ? 'row-reverse' : 'row'
    }
  }

  const setBorderRadius = () => {
    if (isMobile) {
      return isReverse ? '10px 10px 0px 0px' : '0px 0px 10px 10px'
    } else {
      return isReverse ? '10px 0px 0px 10px' : '0px 10px 10px 0px'
    }
  }

  return (
    <div
      className='drop-shadow'
      style={{
        backgroundColor: '#fff',
        borderRadius: 10,
        position: 'relative',
        width: isMobile ? '95%' : '100%',
        height: isMobile ? '100%' : '45vw',
        padding: 0,
        marginTop: marginTop,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          display: 'flex',
          flexDirection: setFlexDirection(),
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '20px 40px 0px',
          }}
        >
          <span
            style={{
              fontSize: 22,
              padding: 0,
            }}
          >
            {heading}
          </span>
          <br />
          <p style={{ fontSize: 16, textAlign: 'left' }}>{description}</p>
        </div>

        <Col md={6} sm={12} height='100%' style={{ padding: 0 }}>
          {isVideo ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: imageBgColor,
                height: '100%',
              }}
            >
              <AutoplayVideo videoUrl={image} maxWidthFull={maxWidthFull} />
            </div>
          ) : (
            <img
              style={{
                width: '100%',
                height: isMobile ? '100%' : '45vw',
                objectFit: 'cover',
                backgroundColor: imageBgColor,
                borderRadius: setBorderRadius(),
              }}
              alt='Pattern'
              src={image}
              thumbnail
            />
          )}
        </Col>
      </div>
    </div>
  )
}

export default FeatureRow
