import React from 'react'
import { Card } from 'react-bootstrap'
import { MdArrowOutward } from 'react-icons/md'

export default function PricingSection() {
  return (
    <div className='main-pricing'>
      <div
        className='center-content'
        style={{
          maxWidth: '680px',
        }}
      >
        <h1>
          Cheaper than your daily Starbucks. Get started with VerifiedX today
        </h1>
        <a href='pricing' style={{ textDecoration: 'none', color: 'inherit' }}>
          <p
            style={{ textAlign: 'center', fontSize: '1.125rem', marginTop: 30 }}
          >
            View pricing plans &gt;
          </p>
        </a>
      </div>

      <div style={{ backgroundColor: '#F8F8F8' }}>
        <div style={{ marginTop: '30px' }}>
          <Card
            style={{
              padding: 16,
              border: 'none',
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ fontSize: '24px', fontFamily: 'SK Concretica' }}
              >
                <p>
                  <strong>Plus</strong>
                </p>
              </Card.Title>
              <ul
                id='pricing-list'
                style={{ listStyleType: 'none', padding: 0 }}
              >
                <li
                  style={{
                    fontSize: 16,
                    paddingBottom: 35,
                  }}
                >
                  Real-time detection
                </li>
                <li
                  style={{
                    fontSize: 16,
                    paddingBottom: 35,
                  }}
                >
                  99.999% accuracy
                </li>
                <li
                  style={{
                    fontSize: 16,
                    paddingBottom: 35,
                  }}
                >
                  Protection across platforms
                </li>
                <li
                  style={{
                    fontSize: 16,
                    paddingBottom: 35,
                  }}
                >
                  QuickCheck feature
                </li>
                <li
                  style={{
                    fontSize: 16,
                    paddingBottom: 35,
                  }}
                >
                  Detailed explanations
                </li>
                <li
                  style={{
                    fontSize: 16,
                    paddingBottom: 35,
                  }}
                >
                  Protection on all devices
                </li>
                <li
                  style={{
                    fontSize: 16,
                    paddingBottom: 35,
                  }}
                >
                  Early access to new features
                </li>
              </ul>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: 16,
                }}
              >
                <span style={{ fontSize: 24 }}>£4.99</span> / month
              </p>
              <a
                href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
                style={{
                  textDecoration: 'none',
                  width: 'fit-content',
                }}
              >
                <div className='start-now-button'>
                  Start now{' '}
                  <span style={{ fontSize: 20 }}>
                    <MdArrowOutward size={20} />
                  </span>
                </div>
              </a>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}
